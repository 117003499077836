import * as Async from "../async";

const routes = [
  {
    name: "Dashboard",
    path: "/",
    layout: Async.ProtectedLayout,
    element: Async.Dashboard,
  },
  {
    name: "Login",
    path: "/ekoag/login",
    layout: Async.PublicLayout,
    element: Async.Login,
  },
  {
    name: "Channels",
    path: "/channels",
    layout: Async.ProtectedLayout,
    element: Async.Channels,
  },
  {
    name: "Page Not Found",
    path: "*",
    layout: Async.PublicLayout,
    element: Async.NotFoundPage,
  },
];

export default routes;
