import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  authToken: undefined,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    storeAuthToken: (state, action) => {
      localStorage.setItem("authToken", action.payload);
      state.authToken = action.payload;
    },
    storeUserData: (state, action) => {
      state.userData = action.payload;
    },
    logout: (state) => {
      localStorage.clear();
      state.userData = {};
      state.authToken = undefined;
    },
  },
});

export const { storeAuthToken, storeUserData, logout } = profileSlice.actions;

export default profileSlice.reducer;
