import { lazy } from "react";

export const ProtectedLayout = lazy(() => import("../layouts/protectedLayout"));

export const PublicLayout = lazy(() => import("../layouts/publicLayout"));

export const Login = lazy(() => import("../pages/login/login.container"));

export const Dashboard = lazy(() =>
  import("../pages/dashboard/dashboard.container")
);

export const Channels = lazy(() =>
  import("../pages/channels/channels.container")
);

export const NotFoundPage = lazy(() =>
  import("../pages/notFoundPage/notFoundPage.container")
);
