import { Suspense } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Spin } from "antd";

import "./App.css";
import routes from "./configs/routes.config";
import "./styles/app.scss";
import "./App.less";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {routes?.map((route, routeIndex) => {
          const { element: Element, layout: Layout, path, name } = route;

          return (
            <Route
              path={path}
              key={routeIndex.toString()}
              element={
                <Layout pageTitle={name}>
                  <Element />
                </Layout>
              }
            />
          );
        })}
      </>
    ),
    {
      basename: "/",
    }
  );

  return (
    <Suspense
      fallback={
        <div
          className="fallback-container"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      }
    >
      {/* This is the spin loading that appears on top left side  */}
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
